@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

/* Form Input */
.form-input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out; /* Smooth transition for border color */
}

.form-input:focus {
  outline: none; /* Remove default focus outline */
  border-color: #3182ce; /* Highlight border color on focus */
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid #1b28d8;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
    spinner-oaa3wk 1.6s infinite linear;
}

@keyframes spinner-bulqg1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes spinner-oaa3wk {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-to-top-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
  z-index: 1000;
}

.scroll-to-top-button:hover {
  background-color: #0056b3;
}

@keyframes rotateCube {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.cube-container {
  width: 100px;
  height: 100px;
  perspective: 500px;
  margin: auto;
  position: relative;
}

.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: rotateCube 7s linear infinite;
}

.cube-face {
  position: absolute;
  width: 100px;
  height: 100px;
  background: white;
  border: 1px solid #ccc;
}

.cube-face img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Positioning the cube faces */
.cube-face.front {
  transform: rotateY(0deg) translateZ(50px);
}
.cube-face.back {
  transform: rotateY(180deg) translateZ(50px);
}
.cube-face.right {
  transform: rotateY(90deg) translateZ(50px);
}
.cube-face.left {
  transform: rotateY(-90deg) translateZ(50px);
}
.cube-face.top {
  transform: rotateX(90deg) translateZ(50px);
}
.cube-face.bottom {
  transform: rotateX(-90deg) translateZ(50px);
}

@media (max-width: 600px) {
  .cube-container {
    width: 50px;
    height: 50px;
  }

  .cube-face {
    width: 50px;
    height: 50px;
  }

  .cube-face.front {
    transform: rotateY(0deg) translateZ(25px);
  }
  .cube-face.back {
    transform: rotateY(180deg) translateZ(25px);
  }
  .cube-face.right {
    transform: rotateY(90deg) translateZ(25px);
  }
  .cube-face.left {
    transform: rotateY(-90deg) translateZ(25px);
  }
  .cube-face.top {
    transform: rotateX(90deg) translateZ(25px);
  }
  .cube-face.bottom {
    transform: rotateX(-90deg) translateZ(25px);
  }
}

@media only screen and (max-width: 639px) {
  .mobile {
    display: none;
  }
}
